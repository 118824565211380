// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/buttons/icon-button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/buttons/icon-button.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

export function PLIconButton({
  icon,
  onClick,
  colorClasses = 'bg-gray-300 hover:bg-gray-400 text-gray-800 dark:bg-neutral-800 dark:hover:bg-neutral-900 dark:text-neutral-400 ',
  disabled
}) {
  const handleClick = e => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };
  return <button onClick={handleClick} disabled={disabled} className={`flex flex-row justify-center items-center gap-2 rounded-full p-4 w-5 h-5 ` + colorClasses + (disabled ? ' cursor-not-allowed opacity-50' : ' cursor-pointer hover:bg-gray-400 dark:hover:bg-neutral-900')}>
      <i className={icon + ' inline-block'}></i>
    </button>;
}
_c = PLIconButton;
var _c;
$RefreshReg$(_c, "PLIconButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;